import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Url } from "../../../context/types/url";
import { animateScroll as scroll } from "react-scroll";
import { addComments } from "../../../context/actions/postaction";
import { getData } from "../../../context/actions/getaction";
import { SlArrowRight } from "react-icons/sl";
import { useTranslation } from "react-i18next";
import Image from "../../images/jo93715bfhet.png";

const InfoCard = () => {
  const { price } = useSelector((state) => state.price);
  const { doviz } = useSelector((state) => state.doviz);
  const Para = price[doviz];
  const [t] = useTranslation("global");
  const { data } = useSelector((state) => state.data);
  const { color } = useSelector((state) => state.color);
  const { id } = useParams();
  const categoriId = data.find((e) => e.id == id);
  const categoriData = data.filter(
    (e) => e.categori_id == categoriId.categori_id
  );
  const products = categoriData.slice(0, 6);
  const dispatch = useDispatch();
  const [imageId, setImageId] = useState();
  const productsInfo = data.find((i) => i.id === parseInt(id));
  const productsImages = productsInfo.images.find((i) => i.id == imageId);
  const filtirColor = color.filter(
    (e) => e.product_code == productsInfo.product_code
  );

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1100,
      smooth: "easeInOutQuint",
    });
    setImageId("");
  };
  const [colorId, setColorId] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [num, setNum] = useState();
  const [responseStatus, setResponseStatus] = useState();

  const handleSubmit = async () => {
    const itemData = {
      id: id,
      c_title: name,
      c_message: message,
      c_email: email,
      c_rate: num,
    };
    try {
      const response = await dispatch(addComments(itemData));
      if (response.status === 200) {
        setResponseStatus("success");
        setName("");
        setNum("");
        setEmail("");
        setMessage("");
        dispatch(getData());
      } else {
        setResponseStatus("error");
      }
    } catch (error) {
      setResponseStatus("error");
    }
  };
  const InfoData = data.filter((e) => e.id == id);
  const comments = InfoData.reduce(
    (acc, comment) => [...acc, ...comment.comments],
    []
  );
  const totalRate = comments.reduce(
    (acc, comment) => parseInt(acc) + parseInt(comment.c_rate),
    0
  );
  const averageRate = totalRate / comments.length;
  const decimalPart = averageRate.toFixed(1);
  const percentage = (averageRate * 20).toFixed(1);

  const StarRating = (
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((index) => (
        <i
          key={index}
          className={`star-rating-icon active bi bi-star${
            index <= Math.floor(decimalPart) ? "-fill" : ""
          }`}
        />
      ))}
    </div>
  );

  return (
    <>
      {/* Page Title*/}
      <div className="page-title-overlap bg-dark pt-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                <li className="breadcrumb-item">
                  <Link className="text-nowrap" to="/" onClick={scrollToTop}>
                    {t("navbar.home")}
                  </Link>
                </li>
                <li>
                  <SlArrowRight color="white" />
                </li>
                <li className="breadcrumb-item text-nowrap">
                  <Link to="/Products" onClick={scrollToTop}>
                    {t("navbar.products")}
                  </Link>
                </li>
                <li>
                  <SlArrowRight color="white" />
                </li>
                <li
                  className="breadcrumb-item text-nowrap active"
                  aria-current="page"
                >
                  {t("products.product")}
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 className="h3 text-light mb-0">
              {productsInfo[t("card.title")]}
            </h1>
          </div>
        </div>
      </div>
      <div className="container">
        {/* Gallery + details*/}
        <div className="bg-light shadow-lg rounded-3 px-4 py-3 mb-5">
          <div className="px-lg-3">
            <div className="row">
              {/* Product gallery*/}
              <div className="col-lg-7 pe-lg-0">
                <div className="product-gallery">
                  <div className="product-gallery-preview order-sm-2">
                    <div
                      className="product-gallery-preview-item active"
                      id="first"
                    >
                      <img
                        className="image-zoom"
                        src={`${Url}/product_imgs/${
                          imageId
                            ? productsImages.product_photo
                            : productsInfo.images[0].product_photo
                        }`}
                        alt="Product image"
                      />
                    </div>
                  </div>
                  <div className="product-gallery-thumblist order-sm-1">
                    {productsInfo.images.map((e, i) => (
                      <div
                        key={i}
                        className="product-gallery-thumblist-item active"
                      >
                        <img
                          onClick={() => {
                            setImageId(e.id);
                          }}
                          src={`${Url}/product_imgs/${e.product_photo}`}
                          alt="Product thumb"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Product details*/}
              <div className="col-lg-5 pt-4 pt-lg-0 image-zoom-pane">
                <div className="product-details ms-auto pb-3">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <a href="#">
                      {StarRating}
                      <span className="d-inline-block fs-sm text-body align-middle mt-1 ms-1">
                        {comments.length} {t("card.reviews")}
                      </span>
                    </a>
                  </div>
                  <div className="mb-3">
                    {doviz}
                    <span className="h3 fw-normal text-accent me-1">
                      {productsInfo
                        ? productsInfo.in_discount == 1
                          ? parseFloat(
                              productsInfo.discount_price * Para
                            ).toFixed(2)
                          : parseFloat(
                              productsInfo.product_price * Para
                            ).toFixed(2)
                        : null}
                    </span>
                    {productsInfo ? (
                      productsInfo.in_discount == 1 ? (
                        <>
                          <del className="text-muted fs-lg me-3">
                            {parseFloat(
                              productsInfo.product_price * Para
                            ).toFixed(2)}
                          </del>
                          <span className="badge bg-danger badge-shadow align-middle mt-n2">
                            {t("card.discount")}
                          </span>
                        </>
                      ) : null
                    ) : null}
                  </div>

                  <div className="fs-sm mb-4">
                    <div className="position-relative me-n4 mb-3">
                      {filtirColor.map((e, key) => (
                        <div
                          onClick={() => {
                            setColorId(e.id);
                          }}
                          key={key}
                          className="form-check form-option form-check-inline mb-2"
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="color"
                            id={e.id}
                            data-bs-label="colorOption"
                            defaultValue="Dark blue/Orange"
                            defaultChecked=""
                          />
                          <label
                            className="form-option-label rounded-circle"
                            htmlFor={e.id}
                          >
                            <span
                              className="form-option-color rounded-circle"
                              style={{ backgroundColor: `${e.colors_code}` }}
                            />
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <form className="mb-grid-gutter">
                    <div className="mb-3 d-flex align-items-center">
                      <a
                        className="btn btn-success btn-shadow d-block w-50 mx-1"
                        target="_blank"
                        href={`https://api.whatsapp.com/send?phone=+96890118236&text=مرحبًا%0Aأنا أود شراء المنتج ${productsInfo.product_code}%0Aيرجى تقديم المزيد من المعلومات حول المنتج وكيفية الشراء.`}
                        data-action="share/whatsapp/share"
                      >
                        <i className="bi bi-whatsapp align-middle mx-1" />
                        {t("card.quick_view")}
                      </a>
                      <button
                        className="btn btn-primary btn-shadow d-block w-50"
                        type="button"
                        onClick={() => {
                          const selectedColorId =
                            colorId ||
                            (filtirColor.length > 0 ? filtirColor[0].id : null);
                          dispatch({
                            type: "add",
                            id: productsInfo.id,
                            colorId: selectedColorId,
                          });
                        }}
                      >
                        <i className="bi bi-cart-plus fs-lg me-2" />
                        {t("card.add_to_cart")}
                      </button>
                    </div>
                  </form>
                  <h5 className="h6 mb-3 pb-2 border-bottom">
                    <i className="bi bi-info-circle text-muted fs-lg align-middle mt-n1 me-2" />
                    {t("card.details")}
                  </h5>
                  <h6
                    dir={t("dir")}
                    className="fs-sm mb-2"
                    dangerouslySetInnerHTML={{
                      __html: productsInfo
                        ? productsInfo[t("card.description")]
                        : null,
                    }}
                  />
                  <h6 dir={t("dir")} className="fs-sm mb-2">
                    {t("card.product_code")}{" "}
                    {productsInfo ? productsInfo.product_code : null}
                  </h6>
                  <div>
                    {/* Sharing*/}
                    <label className="form-label d-inline-block align-middle my-2 me-3">
                      {t("products.share")}
                    </label>
                    <a
                      target="_blank"
                      className="btn-share btn-twitter me-2 my-2"
                      href="https://www.snapchat.com/add/zomord.store?share_id=2obaaFJryRY&locale=ar-SA"
                    >
                      <i className="bi bi-snapchat" />
                      {t("products.twitter")}
                    </a>
                    <a
                      target="_blank"
                      className="btn-share btn-instagram me-2 my-2"
                      href="https://instagram.com/zomord.stor?igshid=OGQ5ZDc2ODk2ZA=="
                    >
                      <i className="bi bi-instagram" />
                      {t("products.instagram")}
                    </a>
                    <a
                      target="_blank"
                      className="btn-share btn-facebook my-2"
                      href="https://www.facebook.com/profile.php?id=100091962536522#"
                    >
                      <i className="bi bi-facebook" />
                      {t("products.facebook")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Reviews*/}
      <div className="border-top border-bottom my-lg-3 py-5">
        <div className="container pt-md-2" id="reviews">
          <div className="row pb-3">
            <div className="col-lg-4 col-md-5">
              <h2 className="h3 mb-4">
                {comments.length}
                {t("card.reviews")}{" "}
              </h2>
              <div>
                {StarRating}
                <div className="fs-ms text-muted">
                  {percentage}%
                  {t("products.of_users_found_this_review_helpful")}
                </div>
              </div>
              <span className="d-inline-block align-middle">
                {decimalPart} {t("products.Overall_rating")}
              </span>
            </div>
          </div>
          <hr className="mt-4 mb-3" />
          <div className="row pt-4">
            {/* Reviews list*/}
            <div className="col-md-7">
              {/* Review*/}
              {comments.map((e) => {
                return (
                  <div
                    key={e.id}
                    className="product-review pb-4 mb-4 border-bottom"
                  >
                    <div className="d-flex mb-3">
                      <div className="d-flex align-items-center me-4 pe-2">
                        <img
                          className="rounded-circle"
                          src={Image}
                          width={50}
                        />
                        <div className="ps-3">
                          <h6 className="fs-sm mb-0">{e.c_title}</h6>
                          <span className="fs-ms text-muted">
                            {new Date(e.created_at).toLocaleDateString(
                              "en-US",
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="star-rating">
                          {[1, 2, 3, 4, 5].map((index) => (
                            <i
                              key={index}
                              className={`star-rating-icon active bi bi-star${
                                index <= Math.floor(e.c_rate) ? "-fill" : ""
                              }`}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <p className="fs-md mb-2">{e.c_message}</p>
                  </div>
                );
              })}
            </div>
            {/* Leave review form*/}
            <div className="col-md-5 mt-2 pt-4 mt-md-0 pt-md-0">
              <div className="bg-secondary py-grid-gutter px-grid-gutter rounded-3">
                <h3 className="h4 pb-2">{t("products.write_a_review")}</h3>
                <form className="needs-validation" method="post" noValidate="">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="review-name">
                      {t("products.your_name")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                      className="form-control"
                      type="text"
                      required
                      id="review-name"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="review-email">
                      {t("products.your_email")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                      className="form-control"
                      type="email"
                      required
                      id="review-email"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="review-rating">
                      {t("products.rating")}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      onChange={(e) => {
                        setNum(e.target.value);
                      }}
                      required
                      id="review-rating"
                    >
                      <option value="">{t("products.choose_rating")}</option>
                      <option value={5}>5 {t("products.stars")}</option>
                      <option value={4}>4 {t("products.stars")}</option>
                      <option value={3}>3 {t("products.stars")}</option>
                      <option value={2}>2 {t("products.stars")}</option>
                      <option value={1}>1 {t("products.stars")}</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="review-text">
                      {t("products.message")}
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      value={message}
                      className="form-control"
                      rows={6}
                      required
                      id="review-text"
                      defaultValue={""}
                    />
                  </div>
                  <Link
                    className="btn btn-primary btn-shadow d-block w-100"
                    onClick={handleSubmit}
                  >
                    {t("products.submit_a_review")}
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Product carousel (Style with)*/}
      <div className="container pt-5">
        <h2 className="h3 text-center pb-4">{t("products.style_with")}</h2>
        <div className="tns-carousel tns-controls-static tns-controls-outside">
          <div className="row mx-n2">
            {/* Product*/}
            {products.map((e, key) => {
              const InfoData = data.filter((i) => i.id == e.id);
              const comments = InfoData.reduce(
                (acc, comment) => [...acc, ...comment.comments],
                []
              );
              const totalRate = comments.reduce(
                (acc, comment) => parseInt(acc) + parseInt(comment.c_rate),
                0
              );
              const averageRate = totalRate / comments.length;
              const decimalPart = averageRate.toFixed(1);

              const StarRating = (
                <div className="star-rating">
                  {[1, 2, 3, 4, 5].map((index) => (
                    <i
                      key={index}
                      className={`star-rating-icon active bi bi-star${
                        index <= Math.floor(decimalPart) ? "-fill" : ""
                      }`}
                    />
                  ))}
                </div>
              );
              return (
                <div
                  key={key}
                  className="col-lg-2 col-md-2 col-sm-2 col-6 px-2 mb-4"
                >
                  <div className="card product-card h-100 border">
                    <Link
                      onClick={scrollToTop}
                      className="card-img-top d-block overflow-hidden"
                      to={`/InfoCard/${e.id}`}
                    >
                      <img
                        src={`${Url}/product_imgs/${e.images[0].product_photo}`}
                        alt="Product"
                        className="card-img"
                      />
                    </Link>
                    <div className="card-body py-2">
                      <a className="product-meta d-block fs-xs pb-1" href="#">
                        {e.categori[t("card.categori")]}
                      </a>
                      <h3 className="product-title fs-sm">
                        <Link to={`/InfoCard/${e.id}`}>
                          {e[t("card.title")]}
                        </Link>
                      </h3>
                      <div className="d-block">
                        <div className="product-price">
                          <span style={{ fontSize: "12px" }}>{doviz}</span>
                          <span className="text-accent m-1">
                            {e.in_discount == 1
                              ? parseFloat(e.discount_price * Para).toFixed(2)
                              : parseFloat(e.product_price * Para).toFixed(
                                  2
                                )}{" "}
                          </span>

                          {e.in_discount == 1 ? (
                            <del className="fs-sm text-muted">
                              {parseFloat(e.product_price * Para).toFixed(2)}
                            </del>
                          ) : null}
                        </div>
                        <div>{StarRating}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoCard;
