import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/layouts/Navbar";
import Footer from "./components/layouts/Footer";
import Home from "./components/pages/home";
import Products from "./components/pages/Products";
import InfoCard from "./components/pages/Products/InfoCard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getColor, getContact, getData, getPrice, getTrend } from "./context/actions/getaction";
import ShopCart from "./components/pages/shop/ShopCart";
import CheckoutDetails from "./components/pages/shop/CheckoutDetails";
import CheckoutPayment from "./components/pages/shop/CheckoutPayment";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Complete from "./components/pages/shop/Complete";


function App() {
  const { error, loading } = useSelector(state => state.data);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContact())
    dispatch(getData())
    dispatch(getColor())
    dispatch(getTrend())
    dispatch(getPrice())
  }, [])


  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>


    );
  }

  if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <span>{error}</span>
      </div>
    );
  }
  return (
    <>
      <main className="page-wrapper font">

        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Products" element={<Products />} />
            <Route path="/Products/:id" element={<Products />} />
            <Route path="/InfoCard/:id" element={<InfoCard />} />
            <Route path="/Shop-Cart" element={<ShopCart />} />
            <Route path="/checkout-details" element={<CheckoutDetails />} />
            <Route path="/checkout-payment" element={<CheckoutPayment />} />
            <Route path="/Complete/:id" element={<Complete />} />
            <Route path="/About" element={<About />} />
            <Route path="/Contact" element={<Contact />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </main>
    </>
  );
}

export default App;
