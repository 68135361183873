import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdDelete, MdKeyboardArrowLeft } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { animateScroll as scroll } from "react-scroll";
import { Url } from "../../../context/types/url";
import { BsCart2 } from "react-icons/bs";
import { PiPackageDuotone } from "react-icons/pi";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import { useTranslation } from "react-i18next";

const ShopCart = () => {
  const { price } = useSelector((state) => state.price);
  const { doviz } = useSelector((state) => state.doviz);
  const Para = price[doviz];
  const [t] = useTranslation("global");
  const [note, setNote] = useState(t("note"));
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1100,
      smooth: "easeInOutQuint",
    });
    dispatch({ type: "note", note });
  };
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { color } = useSelector((state) => state.color);
  const { data } = useSelector((state) => state.data);
  const totalPrice = cart.reduce((total, item) => {
    const product = data.find((product) => product.id === item.id);
    return (
      total +
      [
        product.in_discount == 1
          ? product.discount_price
          : product.product_price,
      ] *
        item.count
    );
  }, 0);
  return (
    <>
      {/* Page Title*/}
      <div className="page-title-overlap bg-dark pt-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                <li className="breadcrumb-item">
                  <Link className="text-nowrap" to="/" onClick={scrollToTop}>
                    {t("navbar.home")}
                  </Link>
                </li>
                <li>
                  <SlArrowRight color="white" />
                </li>
                <li className="breadcrumb-item text-nowrap">
                  <Link to="/Products" onClick={scrollToTop}>
                    {t("navbar.products")}
                  </Link>
                </li>
                <li>
                  <SlArrowRight color="white" />
                </li>
                <li
                  className="breadcrumb-item text-nowrap active"
                  aria-current="page"
                >
                  {t("Shop_Cart.checkout")}
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 className="h3 text-light mb-0">{t("Shop_Cart.your_cart")}</h1>
          </div>
        </div>
      </div>

      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          {/* List of items*/}
          <section className="col-lg-8">
            {/* Steps*/}
            <div className="steps steps-light pt-2 pb-3 mb-5">
              <Link className="step-item active" to="/Shop-Cart">
                <div className="step-progress">
                  <span className="step-count">1</span>
                </div>
                <div className="step-label">
                  <BsCart2 size={20} className="m-1" />
                  {t("Shop_Cart.cart")}
                </div>
              </Link>
              <Link className="step-item " to="/checkout-details">
                <div className="step-progress">
                  <span className="step-count">2</span>
                </div>
                <div className="step-label">
                  <PiPackageDuotone size={20} />
                  {t("Shop_Cart.details")}
                </div>
              </Link>
            </div>
            {/* Item*/}
            {cart.map((e, key) => {
              const itemInCart = data.find((item) => item.id === e.id);
              const filterColor = color.filter((c) => c.id === e.colorId);
              return (
                <div
                  key={key}
                  className="d-sm-flex justify-content-between align-items-center my-2 pb-3 border-bottom"
                >
                  <div className="d-block d-sm-flex align-items-center text-center text-sm-start">
                    <a
                      className="d-inline-block flex-shrink-0 mx-auto me-sm-4"
                      href="#"
                    >
                      <img
                        src={`${Url}/product_imgs/${itemInCart.images[0].product_photo}`}
                        width={160}
                        alt="Product"
                      />
                    </a>
                    <div className="pt-2">
                      <h3 className="product-title fs-base mb-2">
                        <a href="# ">{itemInCart[t("card.title")]}</a>
                      </h3>
                      <div className="fs-sm">
                        <span className="text-muted me-2">
                          {t("Shop_Cart.color")}
                        </span>
                        {filterColor.map((cn, key) => (
                          <span key={key}>{cn[t("card.color")]}</span>
                        ))}
                      </div>
                      <div className="fs-lg text-accent pt-2">
                        <div className="product-price">
                          {doviz}
                          <span className="text-accent m-1">
                            {itemInCart.in_discount == 1
                              ? parseFloat(
                                  itemInCart.discount_price * Para
                                ).toFixed(2)
                              : parseFloat(
                                  itemInCart.product_price * Para
                                ).toFixed(2)}
                          </span>
                          {itemInCart.in_discount == 1 ? (
                            <del className="fs-sm text-muted">
                              {parseFloat(
                                itemInCart.product_price * Para
                              ).toFixed(2)}
                            </del>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="pt-2 pt-sm-0 ps-sm-3 mx-auto mx-sm-0 text-center text-sm-start"
                    style={{ maxWidth: "9rem" }}
                  >
                    <label className="form-label" htmlFor="quantity1">
                      {t("Shop_Cart.quantity")}
                    </label>
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic outlined example"
                    >
                      <button
                        onClick={() =>
                          dispatch({
                            type: "remove",
                            id: itemInCart.id,
                            colorId: e.colorId,
                          })
                        }
                        type="button"
                        className="btn btn-outline-primary"
                      >
                        -
                      </button>
                      <button type="text" className="btn btn-outline-primary">
                        {e.count}
                      </button>
                      <button
                        onClick={() =>
                          dispatch({
                            type: "add",
                            id: e.id,
                            colorId: e.colorId,
                          })
                        }
                        type="button"
                        className="btn btn-outline-primary"
                      >
                        +
                      </button>
                    </div>
                    <button
                      className="btn btn-link px-0 text-danger"
                      type="button"
                    >
                      <MdDelete className=" me-2" />
                      <span
                        onClick={() =>
                          dispatch({
                            type: "delete",
                            id: itemInCart.id,
                            colorId: e.colorId,
                          })
                        }
                        className="fs-sm"
                      >
                        {t("Shop_Cart.remove")}
                      </span>
                    </button>
                  </div>
                </div>
              );
            })}
          </section>
          {/* Sidebar*/}
          <aside className="col-lg-4 pt-4 pt-lg-0 ps-xl-5">
            <div className="bg-white rounded-3 shadow-lg p-4">
              <div className="py-2 px-xl-2">
                <div className="text-center mb-4 pb-3 border-bottom">
                  <h2 className="h6 mb-3 pb-1">{t("Shop_Cart.subtotal")} </h2>
                  <h3 className="fw-normal">
                    <span style={{ fontSize: "20px" }}>{doviz}</span>
                    {parseFloat(totalPrice * Para).toFixed(2)}
                  </h3>
                </div>
                <div className="mb-3 mb-4">
                  <label className="form-label mb-3" htmlFor="order-comments">
                    <span className="badge bg-info fs-xs me-2">
                      {t("Shop_Cart.note")}
                    </span>
                    <span className="fw-medium">
                      {t("Shop_Cart.additional_comments")}
                    </span>
                  </label>
                  <textarea
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                    className="form-control"
                    rows={6}
                    id="order-comments"
                    value={note}
                  />
                </div>
                <div className="accordion" id="order-options"></div>
                <Link
                  to="/checkout-details"
                  onClick={scrollToTop}
                  className="btn btn-primary btn-shadow d-block w-100 mt-4"
                >
                  <i className="bi bi-credit-card fs-lg me-2" />
                  {t("Shop_Cart.proceed_to_checkout")}
                </Link>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </>
  );
};

export default ShopCart;
