import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const Complete = () => {
  const { id } = useParams();
  const [t] = useTranslation("global");
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1100,
      smooth: "easeInOutQuint",
    });
  };
  return (
    <div dir={t("dir")} className="container pb-5 mb-sm-4">
      <div className="pt-5">
        <div className="card py-3 mt-sm-3">
          <div className="card-body text-center">
            <h2 className="h4 pb-3">
              {t("Shop_Cart.thank_you_for_your_order_1")}
            </h2>
            <p className="fs-sm mb-2">
              {t("Shop_Cart.thank_you_for_your_order_2")}
            </p>
            <p className="fs-sm mb-2">
              {t("Shop_Cart.thank_you_for_your_order_3")}{" "}
              <span className="fw-medium">{id}</span>
            </p>
            <p className="fs-sm">{t("Shop_Cart.thank_you_for_your_order_4")}</p>
            <Link
              className="btn btn-secondary mt-3 me-3"
              to="/Products"
              onClick={scrollToTop}
            >
              {t("Shop_Cart.go_back_shopping")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Complete;
