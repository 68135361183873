import React from "react";
import Categories from "./Categories";
import Products from "./Product";
import Banners from "./Banners";
import Follow from "./Follow";
import Brand from "./Brand";
import TrendProduct from "./TrendProduct";

const Home = () => {
  return (
    <div>
      {/* Hero slider*/}
      <section className="tns-carousel tns-controls-lg">
        <div
          id="carouselExampleFade"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner px-lg-5">
            <div
              className="carousel-item active d-lg-flex justify-content-between align-items-center "
              style={{ backgroundColor: "#f9d8ab" }}
            >
              <img
                src="img/home/DSC_1996.JPG"
                className="d-block order-lg-2 me-lg-n5 flex-shrink-0"
                alt="..."
              />
            </div>
            <div
              className="carousel-item active d-lg-flex justify-content-between align-items-center "
              style={{ backgroundColor: "#fec590" }}
            >
              <img
                src="img/home/DSC_6841.JPG"
                className="d-block order-lg-2 me-lg-n5 flex-shrink-0"
                alt="..."
              />
            </div>
            <div
              className="carousel-item active d-lg-flex justify-content-between align-items-center "
              style={{ backgroundColor: "#fddab4" }}
            >
              <img
                src="img/home/DSC_3758.JPG"
                className="d-block order-lg-2 me-lg-n5 flex-shrink-0"
                alt="..."
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>
      {/* Popular categories*/}
      <Categories />
      {/* Products grid (Trending products)*/}
      <TrendProduct />
      <Products />
      {/* Banners*/}
      {/* <Banners /> */}
      {/* Shop by brand*/}
      <Brand />
      {/* Blog + Instagram info cards*/}
      <Follow />
    </div>
  );
};

export default Home;
