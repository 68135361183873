import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { Url } from "../../../context/types/url";
import { useTranslation } from "react-i18next";

const Categories = () => {
  const [t] = useTranslation("global");
  const { data } = useSelector((state) => state.data);
  const Categories = data.map((e) => e.categori);
  const uniqueCategories = new Set();
  const [activeCategory, setActiveCategory] = useState(null);

  const handleCategoryClick = (categoryName) => {
    setActiveCategory(categoryName === activeCategory ? null : categoryName);
  };
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1100,
      smooth: "easeInOutQuint",
    });
  };
  return (
    <section
      dir={t("dir")}
      className="container position-relative pt-3 pt-lg-0 pb-5 mt-lg-n10"
      style={{ zIndex: 10 }}
    >
      <div className="row">
        <div className="col-xl-8 col-lg-9">
          <div className="card border-0 shadow-lg">
            <div className="card-body px-3 pt-grid-gutter pb-0">
              <div className="row g-0 ps-1">
                {Categories.map((e, key) => {
                  if (!uniqueCategories.has(e.categori_name_ar)) {
                    uniqueCategories.add(e.categori_name_ar);
                    return (
                      <div
                        onClick={scrollToTop}
                        key={key}
                        className="col-sm-4 px-2 mb-grid-gutter"
                      >
                        <Link
                          to={`/Products/${e.id}`}
                          className="d-block text-center text-decoration-none me-1"
                          onClick={() => handleCategoryClick(e.id)}
                        >
                          <img
                            className="d-block rounded mb-3"
                            src={`${Url}/categori_imgs/${e.categori_photo}`}
                            alt="Men"
                          />
                          <h3 className="fs-base pt-1 mb-0">
                            {e[t("card.categori")]}
                          </h3>
                        </Link>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Categories;
