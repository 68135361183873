import React from "react";
import { Link } from "react-router-dom";
import { Url } from "../../../context/types/url";
import { useDispatch, useSelector } from "react-redux";
import { BsCart2 } from "react-icons/bs";
import { PiPackageDuotone } from "react-icons/pi";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import { animateScroll as scroll } from "react-scroll";

const CheckoutPayment = () => {
  const { price } = useSelector((state) => state.price);
  const { doviz } = useSelector((state) => state.doviz);
  const Para = price[doviz];
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1100,
      smooth: "easeInOutQuint",
    });
  };
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { data } = useSelector((state) => state.data);
  const totalPrice = cart.reduce((total, item) => {
    const product = data.find((product) => product.id === item.id);
    return (
      total +
      [
        product.in_discount == 1
          ? product.discount_price
          : product.product_price,
      ] *
        item.count
    );
  }, 0);
  const totalItems = cart.reduce((total, item) => total + item.count, 0);
  return (
    <>
      <div className="page-title-overlap bg-dark pt-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                <li className="breadcrumb-item">
                  <Link className="text-nowrap" to="/" onClick={scrollToTop}>
                    Home
                  </Link>
                </li>
                <li>
                  <SlArrowRight color="white" />
                </li>
                <li className="breadcrumb-item text-nowrap">
                  <Link to="/Products" onClick={scrollToTop}>
                    Products
                  </Link>
                </li>
                <li>
                  <SlArrowRight color="white" />
                </li>
                <li
                  className="breadcrumb-item text-nowrap active"
                  aria-current="page"
                >
                  Checkout
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 className="h3 text-light mb-0">Checkout</h1>
          </div>
        </div>
      </div>
      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          <section className="col-lg-8">
            {/* Steps*/}
            <div className="steps steps-light pt-2 pb-3 mb-5">
              <Link className="step-item active" to="/Shop-Cart">
                <div className="step-progress">
                  <span className="step-count">1</span>
                </div>
                <div className="step-label">
                  <BsCart2 size={20} className="m-1" />
                  Cart
                </div>
              </Link>
              <Link className="step-item active" to="/checkout-details">
                <div className="step-progress">
                  <span className="step-count">2</span>
                </div>
                <div className="step-label">
                  <PiPackageDuotone size={20} />
                  Details
                </div>
              </Link>
              <Link className="step-item active" to="/checkout-payment">
                <div className="step-progress">
                  <span className="step-count">3</span>
                </div>
                <div className="step-label">
                  <i className="bi bi-credit-card" />
                  Payment
                </div>
              </Link>
            </div>
            {/* Payment methods accordion*/}
            <h2 className="h6 pb-3 mb-2">Choose payment method</h2>
            <div
              className="accordion-collapse collapse show"
              id="card"
              data-bs-parent="#payment-method"
            >
              <div className="accordion-body">
                <p className="fs-sm">
                  We accept following credit cards:&nbsp;&nbsp;
                  <img
                    className="d-inline-block align-middle"
                    src="img/cards.png"
                    width={187}
                    alt="Cerdit Cards"
                  />
                </p>
                <div className="credit-card-wrapper" />
                <form className="credit-card-form row">
                  <div className="mb-3 col-sm-6">
                    <input
                      className="form-control"
                      type="text"
                      name="number"
                      placeholder="Card Number"
                      required=""
                    />
                  </div>
                  <div className="mb-3 col-sm-6">
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder="Full Name"
                      required=""
                    />
                  </div>
                  <div className="mb-3 col-sm-3">
                    <input
                      className="form-control"
                      type="text"
                      name="expiry"
                      placeholder="MM/YY"
                      required=""
                    />
                  </div>
                  <div className="mb-3 col-sm-3">
                    <input
                      className="form-control"
                      type="text"
                      name="cvc"
                      placeholder="CVC"
                      required=""
                    />
                  </div>
                </form>
              </div>
            </div>
            {/* Navigation (desktop)*/}
            <div className="d-none d-lg-flex pt-4">
              <div className="w-50 pe-3">
                <Link
                  className="btn btn-secondary d-block w-100"
                  to="/checkout-details"
                  onClick={scrollToTop}
                >
                  <SlArrowLeft className="ci-arrow-left mt-sm-0 me-1" />
                  <span className="d-none d-sm-inline">Back to Details</span>
                  <span className="d-inline d-sm-none">Back</span>
                </Link>
              </div>
              <div className="w-50 ps-2">
                <Link
                  className="btn btn-primary d-block w-100"
                  to="/Complete"
                  onClick={scrollToTop}
                >
                  <span className="d-none d-sm-inline">Complete order</span>
                  <span className="d-inline d-sm-none">Complete</span>
                  <SlArrowRight className="ci-arrow-right mt-sm-0 ms-1" />
                </Link>
              </div>
            </div>
          </section>
          {/* Sidebar*/}
          <aside className="col-lg-4 pt-4 pt-lg-0 ps-xl-5">
            <div className="bg-white rounded-3 shadow-lg p-4 ms-lg-auto">
              <div className="py-2 px-xl-2">
                <div className="widget mb-3">
                  <h2 className="widget-title text-center">Order summary</h2>
                  {cart.map((e) => {
                    const itemInCart = data.find((item) => item.id === e.id);
                    return (
                      <div
                        key={itemInCart.id}
                        className="d-flex align-items-center pb-2 border-bottom"
                      >
                        <a className="d-block flex-shrink-0" href="#">
                          <img
                            src={`${Url}/product_imgs/${itemInCart.images[0].product_photo}`}
                            width={64}
                            alt="Product"
                          />
                        </a>
                        <div className="ps-2">
                          <h6 className="widget-product-title">
                            <a href="#">{itemInCart.product_title_ar}</a>
                          </h6>
                          <div className="widget-product-meta">
                            <span className="text-accent me-2">
                              ${itemInCart.product_price}
                            </span>
                            <span className="text-muted">x {e.count}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <ul className="list-unstyled fs-sm pb-2 border-bottom">
                  <li className="d-flex justify-content-between align-items-center">
                    <span className="me-2">Subtotal:</span>
                    <span className="text-end">${totalPrice.toFixed(2)}</span>
                  </li>
                  <li className="d-flex justify-content-between align-items-center">
                    <span className="me-2">Shipping:</span>
                    <span className="text-end">—</span>
                  </li>
                  <li className="d-flex justify-content-between align-items-center">
                    <span className="me-2">Taxes:</span>
                    <span className="text-end">
                      $9.<small>50</small>
                    </span>
                  </li>
                  <li className="d-flex justify-content-between align-items-center">
                    <span className="me-2">Discount:</span>
                    <span className="text-end">—</span>
                  </li>
                </ul>
                <h3 className="fw-normal text-center my-4">
                  {doviz}
                  {parseFloat(totalPrice * Para).toFixed(2)}
                </h3>
              </div>
            </div>
          </aside>
        </div>
        {/* Navigation (mobile)*/}
        <div className="row d-lg-none">
          <div className="col-lg-8">
            <div className="d-flex pt-4 mt-3">
              <div className="w-50 pe-3">
                <Link
                  className="btn btn-secondary d-block w-100"
                  to="/checkout-details"
                  onClick={scrollToTop}
                >
                  <SlArrowLeft className="ci-arrow-left mt-sm-0 me-1" />
                  <span className="d-none d-sm-inline">Back to Details</span>
                  <span className="d-inline d-sm-none">Back</span>
                </Link>
              </div>
              <div className="w-50 ps-2">
                <Link
                  className="btn btn-primary d-block w-100"
                  to="/Complete"
                  onClick={scrollToTop}
                >
                  <span className="d-none d-sm-inline">Complete order</span>
                  <span className="d-inline d-sm-none">Complete</span>
                  <SlArrowRight className="ci-arrow-right mt-sm-0 ms-1" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutPayment;
