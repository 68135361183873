import React from "react";
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";
import { MdPayment } from "react-icons/md";
import { Link } from "react-router-dom";
import Logo from "../images/889966.png";
import { animateScroll as scroll } from "react-scroll";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const Footer = () => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const handleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1100,
      smooth: "easeInOutQuint",
    });
  };
  return (
    <>
      {/* Footer*/}
      <footer className="footer bg-dark pt-5">
        <div className="pt-5 ">
          <div className="container">
            <div dir={t("dir")} className="row pb-3 ">
              <div className="col-md-3 col-sm-6 mb-4">
                <div className="d-flex">
                  <HiOutlineRocketLaunch
                    className="text-primary"
                    style={{ fontSize: "2.25rem" }}
                  />
                  <div className="ps-3 pe-3">
                    <h6 className="fs-base text-light mb-1">
                      {t("footer.fast_and_free_delivery")}
                    </h6>
                    <p className="mb-0 fs-ms text-light opacity-50">
                      {t("footer.free_delivery_for_all_orders")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-4">
                <div className="d-flex">
                  <FaMoneyBillTransfer
                    className=" text-primary"
                    style={{ fontSize: "2.25rem" }}
                  />
                  <div className="ps-3 pe-3">
                    <h6 className="fs-base text-light mb-1">
                      {t("footer.money_back_guarantee")}
                    </h6>
                    <p className="mb-0 fs-ms text-light opacity-50">
                      {t("footer.we_return_money_within")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-4">
                <div className="d-flex">
                  <BiSupport
                    className=" text-primary"
                    style={{ fontSize: "2.25rem" }}
                  />
                  <div className="ps-3 pe-3">
                    <h6 className="fs-base text-light mb-1">
                      {t("footer.customer_support")}
                    </h6>
                    <p className="mb-0 fs-ms text-light opacity-50">
                      {t("footer.friendly_customer_support")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 mb-4">
                <div className="d-flex">
                  <MdPayment
                    className=" text-primary"
                    style={{ fontSize: "2.25rem" }}
                  />
                  <div className="ps-3 pe-3">
                    <h6 className="fs-base text-light mb-1">
                      {t("footer.secure_online_payment")}
                    </h6>
                    <p className="mb-0 fs-ms text-light opacity-50">
                      {t("footer.secure_certificate")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="hr-light mb-5" />
            <div className="row pb-2">
              <div className="col-md-6 text-center text-md-start mb-4">
                <div className="text-nowrap mb-4">
                  <a
                    className="d-inline-block align-middle mt-n1 me-3"
                    href="#"
                  >
                    <img
                      className="d-block"
                      src={Logo}
                      width={100}
                      alt="Cartzilla"
                    />
                  </a>
                  <div className="btn-group dropdown disable-autohide">
                    <button
                      className="btn btn-outline-light border-light btn-sm dropdown-toggle px-2"
                      type="button"
                      data-bs-toggle="dropdown"
                    >
                      <img
                        className="me-2"
                        src={`img/flags/${t("img")}`}
                        width={20}
                      />
                      {t("language")} / $
                    </button>
                    <ul className="dropdown-menu my-1">
                      <li className="dropdown-item">
                        <select
                          onChange={(e) => {
                            dispatch({ type: "DOVIZ", doviz: e.target.value });
                          }}
                          className="form-select form-select-sm"
                        >
                          <option value="OMR"> OMR</option>
                          <option value="USD"> USD</option>
                          <option value="SAR"> SAR</option>
                          <option value="KWD"> KWD</option>
                          <option value="BHD"> BHD</option>
                          <option value="AED"> AED</option>
                        </select>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item pb-1"
                          onClick={() => {
                            handleLanguage("en");
                          }}
                        >
                          <img
                            className="me-2"
                            src="img/flags/en.png"
                            width={20}
                            alt="English"
                          />
                          English
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item pb-1"
                          onClick={() => {
                            handleLanguage("ar");
                          }}
                        >
                          <img
                            className="me-2"
                            src="img/flags/sa.png"
                            width={20}
                            alt="العربية"
                          />
                          العربية
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="widget widget-links widget-light">
                  <ul className="widget-list d-flex flex-wrap justify-content-center justify-content-md-start">
                    <li className="widget-list-item me-4">
                      <Link
                        onClick={scrollToTop}
                        className="widget-list-link"
                        to="/"
                      >
                        {t("navbar.home")}
                      </Link>
                    </li>
                    <li className="widget-list-item me-4">
                      <Link
                        onClick={scrollToTop}
                        className="widget-list-link"
                        to="/Products"
                      >
                        {t("navbar.products")}
                      </Link>
                    </li>
                    <li className="widget-list-item me-4">
                      <Link
                        onClick={scrollToTop}
                        className="widget-list-link"
                        to="/About"
                      >
                        {t("navbar.about")}
                      </Link>
                    </li>
                    <li className="widget-list-item me-4">
                      <Link
                        onClick={scrollToTop}
                        className="widget-list-link"
                        to="/Contact"
                      >
                        {t("navbar.contact")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 text-center text-md-end mb-4">
                <div className="mb-3">
                  <a
                    target="_blank"
                    className="btn-social bs-light bs-twitter ms-2 mb-2"
                    href="https://www.snapchat.com/add/zomord.store?share_id=2obaaFJryRY&locale=ar-SA"
                  >
                    <i className="bi bi-snapchat" />
                  </a>
                  <a
                    target="_blank"
                    className="btn-social bs-light bs-facebook ms-2 mb-2"
                    href="https://www.facebook.com/profile.php?id=100091962536522#"
                  >
                    <i className="bi bi-facebook" />
                  </a>
                  <a
                    target="_blank"
                    className="btn-social bs-light bs-instagram ms-2 mb-2"
                    href="https://instagram.com/zomord.stor?igshid=OGQ5ZDc2ODk2ZA=="
                  >
                    <i className="bi bi-instagram " />
                  </a>
                </div>
                {/* <img
                  className="d-inline-block"
                  src="img/cards-alt.png"
                  width={187}
                  alt="Payment methods"
                /> */}
              </div>
            </div>
            <div className="pb-4 fs-xs text-light opacity-50 text-center text-md-start">
              © All rights reserved. Made by{" "}
              <a
                className="text-light"
                href="http://al-ayadi.com/"
                target="_blank"
                rel="noopener"
              >
                Alayadi Digital Solutions
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
