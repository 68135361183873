// reducerGetData.js
const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const reducerGetData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_START":
      return { ...state, loading: true, error: null };
    case "GET_DATA_SUCCESS":
      return { ...state, loading: false, data: action.payload };
    case "GET_DATA_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialStateTrend = {
  loading: false,
  trend: [],
  error: null,
};

export const reducerGetTrend = (state = initialStateTrend, action) => {
  switch (action.type) {
    case "GET_TREND_START":
      return { ...state, loading: true, error: null };
    case "GET_TREND_SUCCESS":
      return { ...state, loading: false, trend: action.payload };
    case "GET_TREND_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialStateContact = {
  loading: false,
  contact: [],
  error: null,
};

export const reducerGetContact = (state = initialStateContact, action) => {
  switch (action.type) {
    case "GET_CONTACT_START":
      return { ...state, loading: true, error: null };
    case "GET_CONTACT_SUCCESS":
      return { ...state, loading: false, contact: action.payload };
    case "GET_CONTACT_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialColor = {
  loading: false,
  color: [],
  error: null,
};

export const reducerGetColor = (state = initialColor, action) => {
  switch (action.type) {
    case "GET_COLOR_START":
      return { ...state, loading: true, error: null };
    case "GET_COLOR_SUCCESS":
      return { ...state, loading: false, color: action.payload };
    case "GET_COLOR_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialStatePrice = {
  loading: false,
  price: { USD: "1.0" },
  error: null,
};

export const reducerGetPrice = (state = initialStatePrice, action) => {
  switch (action.type) {
    case "GET_PRICE_START":
      return { ...state, loading: true, error: null };
    case "GET_PRICE_SUCCESS":
      return { ...state, loading: false, price: action.payload };
    case "GET_PRICE_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
