import React, { useState } from "react";
import Card from "./Card";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";
const Products = () => {
  const { id } = useParams();
  const { data } = useSelector((state) => state.data);
  const [t] = useTranslation("global");

  const Categories = data.map((e) => e.categori);
  const Brand = data.map((e) => e.marka);
  const uniqueCategories = new Set();
  const [activeCategory, setActiveCategory] = useState(id);
  const [activeBrand, setActiveBrand] = useState([]);

  const handleCategoryClick = (id) => {
    setActiveCategory(id === activeCategory ? null : id);
  };

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setActiveBrand((prev) => [...prev, value]);
    } else {
      setActiveBrand((prev) => prev.filter((brand) => brand !== value));
    }
  };

  return (
    <>
      <div dir={t("dir")} className="page-title-overlap bg-dark pt-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                <li className="breadcrumb-item">
                  <Link className="text-nowrap" to="/">
                    {t("navbar.home")}
                  </Link>
                </li>
                <li
                  className="breadcrumb-item text-nowrap active"
                  aria-current="page"
                >
                  <IoIosArrowForward size={18} color="white" />
                  {t("navbar.products")}
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 className="h3 text-light mb-0">{t("navbar.products")}</h1>
          </div>
        </div>
      </div>
      <div dir={t("dir")} className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          {/* Sidebar*/}
          <aside className="col-lg-4">
            {/* Sidebar*/}
            <div
              className="offcanvas offcanvas-collapse bg-white w-100 rounded-3 shadow-lg py-1"
              id="shop-sidebar"
              style={{ maxWidth: "22rem" }}
            >
              <div className="offcanvas-header align-items-center shadow-sm">
                <h2 className="h5 mb-0">{t("products.filters")}</h2>
                <button
                  className="btn-close ms-auto"
                  type="button"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                />
              </div>
              <div className="offcanvas-body py-grid-gutter px-lg-grid-gutter">
                {/* Categories*/}
                <div className="widget widget-categories mb-4 pb-4 border-bottom">
                  <h3 className="widget-title">{t("products.categories")}</h3>
                  <div className="accordion mt-n1" id="shop-categories">
                    {Categories.map((e, key) => {
                      if (!uniqueCategories.has(e.categori_name_ar)) {
                        uniqueCategories.add(e.categori_name_ar);
                        return (
                          <div key={key} className="accordion-item">
                            <h3 className="accordion-header">
                              <a
                                className={`accordion-button ${
                                  e.id == activeCategory ? "" : "collapsed"
                                }`}
                                href={`#${e.categori_name}`}
                                role="button"
                                data-bs-toggle="collapse"
                                aria-expanded={
                                  e.id == activeCategory ? "true" : "false"
                                }
                                aria-controls="shoes"
                                onClick={() => handleCategoryClick(e.id)}
                              >
                                {e[t("card.categori")]}
                              </a>
                            </h3>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
                {/* Filter by Brand*/}
                <div className="widget widget-filter mb-4 pb-4 border-bottom">
                  <h3 className="widget-title">{t("products.brand")}</h3>
                  <ul
                    className="widget-list widget-filter-list list-unstyled pt-1"
                    style={{ maxHeight: "11rem" }}
                    data-simplebar=""
                    data-simplebar-auto-hide="false"
                  >
                    {Brand.map((e, key) => {
                      if (!uniqueCategories.has(e.marka_adi)) {
                        uniqueCategories.add(e.marka_adi);
                        return (
                          <li
                            key={key}
                            className="widget-filter-item d-flex justify-content-between align-items-center mb-1"
                          >
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`${e.marka_adi}`}
                                value={e.id}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label widget-filter-item-text"
                                htmlFor={`${e.marka_adi}`}
                              >
                                {e.marka_adi}
                              </label>
                            </div>
                          </li>
                        );
                      }
                      return null;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </aside>
          {/* Content  */}
          <Card activeCategory={activeCategory} activeBrand={activeBrand} />
        </div>
      </div>
    </>
  );
};

export default Products;
