import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import pdf from "../images/about.pdf";
const About = () => {
  const [t] = useTranslation("global");
  return (
    <>
      {/* Row: Shop online*/}
      <section dir={t("dir")} className="row g-0">
        <div
          className="col-md-6 bg-position-center bg-size-cover bg-secondary"
          style={{
            minHeight: "15rem",
            backgroundImage: "url(img/about/01.jpg)",
          }}
        />
        <div className="col-md-6 px-3 px-md-5 py-5">
          <div className="mx-auto py-lg-5" style={{ maxWidth: "35rem" }}>
            <h2 className="h3 pb-3">
              {t("about.welcome_to_our_luxurious_website")}
            </h2>
            <p className="fs-sm pb-3 text-muted">
              {t("about.we_offer_you_an_exceptional")}
            </p>
            <Link className="btn btn-primary btn-shadow mx-1" to="/Products">
              {t("about.view_products")}
            </Link>
            <a className="btn btn-info btn-shadow" href={pdf} target="_blank">
              {t("about.pdf")}
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
