import React from "react";
import { useSelector } from "react-redux";
import { Url } from "../../../context/types/url";
import { useTranslation } from "react-i18next";

const Brand = () => {
  const [t] = useTranslation("global");
  const { data } = useSelector((state) => state.data);
  const uniqueCategories = new Set();
  const Brand = data.map((e) => e.marka);

  return (
    <section className="container py-lg-4 mb-4">
      <h2 className="h3 text-center pb-4">{t("home.brands")}</h2>
      <div className="row">
        {Brand.map((e, key) => {
          if (!uniqueCategories.has(e.marka_foto)) {
            uniqueCategories.add(e.marka_foto);
            return (
              <div key={key} className="col-md-3 col-sm-4 col-6">
                <div className="d-block bg-white shadow-sm rounded-3 py-3 py-sm-4 mb-grid-gutter">
                  <img
                    className="d-block mx-auto"
                    src={`${Url}/marka_foto/${e.marka_foto}`}
                    style={{ width: 150 }}
                    alt="Brand"
                  />
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </section>
  );
};

export default Brand;
