import React, { useState } from "react";
import { IoLocationOutline } from "react-icons/io5";
import { TbClockHour3 } from "react-icons/tb";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { SlArrowRight } from "react-icons/sl";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { addContact } from "../../context/actions/postaction";
import { useDispatch } from "react-redux";
import { getContact } from "../../context/actions/getaction";

const Contact = () => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  const [colorId, setColorId] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [num, setNum] = useState();
  const [responseStatus, setResponseStatus] = useState();

  const handleSubmit = async () => {
    const itemData = {
      c_title: name,
      c_message: message,
      c_email: email,
      c_rate: num,
    };
    try {
      const response = await dispatch(addContact(itemData));
      if (response.status === 200) {
        setResponseStatus("success");
        setName("");
        setNum("");
        setEmail("");
        setMessage("");
      } else {
        setResponseStatus("error");
      }
    } catch (error) {
      setResponseStatus("error");
    }
  };

  console.log(responseStatus);
  return (
    <>
      {/* Page Title (Light)*/}
      <div dir={t("dir")} className="bg-secondary py-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-start">
                <li className="breadcrumb-item">
                  <Link className="text-nowrap" to="/">
                    {t("navbar.home")}
                  </Link>
                </li>
                <li
                  className="breadcrumb-item text-nowrap active"
                  aria-current="page"
                >
                  <SlArrowRight />
                  {t("navbar.contact")}
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 className="h3 mb-0">{t("navbar.contact")}</h1>
          </div>
        </div>
      </div>
      {/* Contact detail cards*/}
      <section dir={t("dir")} className="container-fluid pt-grid-gutter">
        <div className="row">
          {/* <div className="col-xl-3 col-sm-6 mb-grid-gutter">
            <a className="card h-100" href="#map" data-scroll="">
              <div className="card-body text-center">
                <IoLocationOutline className="ci-location h3 mt-2 mb-4 text-primary" />
                <h3 className="h6 mb-2">{t("contact.main_store_address")} </h3>
                <p className="fs-sm text-muted">
                  396 Lillian Blvd, Holbrook, NY 11741, USA
                </p>
              </div>
            </a>
          </div> */}
          <div className="col-xl-4 col-sm-6 mb-grid-gutter">
            <div className="card h-100">
              <div className="card-body text-center">
                <TbClockHour3 className="ci-time h3 mt-2 mb-4 text-primary" />
                <h3 className="h6 mb-3">{t("contact.working_hours")}</h3>
                <ul className="list-unstyled fs-sm text-muted mb-0">
                  <li>Mon - Fri: 10AM - 7PM</li>
                  <li className="mb-0">Sta: 11AM - 5PM</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-6 mb-grid-gutter">
            <div className="card h-100">
              <div className="card-body text-center">
                <BsTelephone className="ci-phone h3 mt-2 mb-4 text-primary" />
                <h3 className="h6 mb-3">{t("contact.phone_numbers")}</h3>
                <ul className="list-unstyled fs-sm mb-0">
                  <li>
                    <span className="text-muted me-1">
                      {t("contact.for_customers")}
                    </span>
                    <a className="nav-link-style" href="tel:+96890118236">
                      +96890118236
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-6 mb-grid-gutter">
            <div className="card h-100">
              <div className="card-body text-center">
                <AiOutlineMail className="ci-mail h3 mt-2 mb-4 text-primary" />
                <h3 className="h6 mb-3">{t("contact.email_addresses")}</h3>
                <ul className="list-unstyled fs-sm mb-0">
                  <li>
                    <span className="text-muted me-1">
                      {t("contact.for_customers")}
                    </span>
                    <a
                      className="nav-link-style"
                      href="mailto:info@miss-chic.com"
                    >
                      info@miss-chic.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Split section: Map + Contact form*/}
      <div dir={t("dir")} className="container-fluid px-0" id="map">
        <div className="row g-0">
          {/* <div className="col-lg-6 iframe-full-height-wrap">
            <iframe
              className="iframe-full-height"
              width={600}
              height={250}
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d6307.1048434861395!2d56.28483402406535!3d19.956954558798646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sar!2str!4v1696244174444!5m2!1sar!2str"
            />
          </div> */}
          <div className="col-lg-8 mx-auto px-4 px-xl-5 py-5 border-top">
            <h2 className="h4 mb-4">{t("contact.drop_us_a_line")}</h2>
            <form className="needs-validation mb-3" noValidate="">
              <div className="row g-3">
                <div className="col-sm-6">
                  <label className="form-label" htmlFor="cf-name">
                    {t("contact.your_name")}&nbsp;
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    value={name}
                    className="form-control"
                    type="text"
                    id="cf-name"
                    placeholder={t("contact.your_name")}
                    required
                  />
                </div>
                <div className="col-sm-6">
                  <label className="form-label" htmlFor="cf-email">
                    {t("contact.email_address")} &nbsp;
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                    className="form-control"
                    type="email"
                    id="cf-email"
                    placeholder={t("contact.email_address")}
                    required
                  />
                </div>
                <div className="col-sm-6">
                  <label className="form-label" htmlFor="cf-phone">
                    {t("contact.your_phone")}&nbsp;
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    onChange={(e) => {
                      setNum(e.target.value);
                    }}
                    required
                    id="review-rating"
                  >
                    <option value="">{t("products.choose_rating")}</option>
                    <option value={5}>5 {t("products.stars")}</option>
                    <option value={4}>4 {t("products.stars")}</option>
                    <option value={3}>3 {t("products.stars")}</option>
                    <option value={2}>2 {t("products.stars")}</option>
                    <option value={1}>1 {t("products.stars")}</option>
                  </select>
                </div>

                <div className="col-12">
                  <label className="form-label" htmlFor="cf-message">
                    {t("contact.message")}&nbsp;
                    <span className="text-danger">*</span>
                  </label>
                  <textarea
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    value={message}
                    className="form-control"
                    id="cf-message"
                    rows={6}
                    placeholder={t("contact.message")}
                    required
                    defaultValue={""}
                  />
                  <Link onClick={handleSubmit} className="btn btn-primary mt-4">
                    {t("contact.send_message")}
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
