import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { Url } from "../../context/types/url";
import Logo from "../images/885544.png";
import En from "../images/en.png";
import Om from "../images/oman.png";
import { BsTelephone } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { price } = useSelector((state) => state.price);
  const { doviz } = useSelector((state) => state.doviz);
  const Para = price[doviz];
  const [t, i18n] = useTranslation("global");
  const handleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const { data } = useSelector((state) => state.data);
  const Categories = data.map((e) => e.categori);
  const uniqueCategories = new Set();
  const [activeCategory, setActiveCategory] = useState(null);

  const handleCategoryClick = (categoryName) => {
    setActiveCategory(categoryName === activeCategory ? null : categoryName);
  };

  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsNavbarFixed(true);
      } else {
        setIsNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1100,
      smooth: "easeInOutQuint",
    });
  };
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const totalPrice = cart.reduce((total, item) => {
    const product = data.find((product) => product.id === item.id);
    return (
      total +
      [
        product.in_discount == 1
          ? product.discount_price
          : product.product_price,
      ] *
        item.count
    );
  }, 0);
  const totalItems = cart.reduce((total, item) => total + item.count, 0);

  return (
    <div className={isNavbarFixed ? "border-bottom fixed-top " : ""}>
      {/* Navbar 3 Level (Light)*/}
      <header dir={t("dir")} className="shadow-sm">
        {/* Topbar*/}
        <div className="topbar topbar-dark bg-dark">
          <div className="container">
            <div className="topbar-text dropdown d-md-none">
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="tel:+96890118236">
                    <BsTelephone className="ci-support text-muted me-2" />
                    +96890118236
                  </a>
                </li>
              </ul>
            </div>
            <div className="topbar-text text-nowrap d-none d-md-inline-block">
              <BsTelephone className="ci-support" />
              <span className="text-muted me-1">{t("support")}</span>
              <a className="topbar-link" href="tel:+96890118236">
                +96890118236
              </a>
            </div>
            <div className="ms-3 text-nowrap">
              <div className="topbar-text dropdown disable-autohide">
                <a
                  className="topbar-link dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <img
                    className="me-2"
                    src={`img/flags/${t("img")}`}
                    width={20}
                  />
                  {t("language")} / {doviz}
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li className="dropdown-item">
                    <select
                      onChange={(e) => {
                        dispatch({ type: "DOVIZ", doviz: e.target.value });
                      }}
                      className="form-select form-select-sm"
                    >
                      <option value="OMR"> OMR</option>
                      <option value="USD"> USD</option>
                      <option value="SAR"> SAR</option>
                      <option value="KWD"> KWD</option>
                      <option value="BHD"> BHD</option>
                      <option value="AED"> AED</option>
                    </select>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item pb-1"
                      onClick={() => {
                        handleLanguage("en");
                      }}
                    >
                      <img
                        className="me-2"
                        src="img/flags/en.png"
                        width={20}
                        alt="English"
                      />
                      English
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item pb-1"
                      onClick={() => {
                        handleLanguage("ar");
                      }}
                    >
                      <img
                        className="me-2"
                        src={"img/flags/sa.png"}
                        width={20}
                        alt="العربية"
                      />
                      العربية
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Remove "navbar-sticky" class to make navigation bar scrollable with the page.*/}
        <div className="navbar-sticky bg-light">
          <div className="navbar navbar-expand-lg navbar-light">
            <div className="container">
              <Link
                className="navbar-brand d-none d-sm-block flex-shrink-0"
                to="/"
                onClick={scrollToTop}
              >
                <img src={Logo} width={142} alt="Cartzilla" />
              </Link>
              <Link
                className="navbar-brand d-sm-none flex-shrink-0 me-2"
                to="/"
                onClick={scrollToTop}
              >
                <img src={Logo} width={120} alt="Cartzilla" />
              </Link>
              <div className="input-group d-none d-lg-flex mx-4">
                <input
                  className="form-control rounded-end pe-5"
                  type="text"
                  placeholder={t("search")}
                />
                <i className="bi bi-search position-absolute top-50 end-0 translate-middle-y text-muted fs-base me-3" />
              </div>
              <div className="navbar-toolbar d-flex flex-shrink-0 align-items-center">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarCollapse"
                >
                  <span className="navbar-toggler-icon" />
                </button>

                <div className="navbar-tool dropdown ms-3">
                  <Link
                    to="/Shop-Cart"
                    onClick={scrollToTop}
                    className="navbar-tool-icon-box bg-secondary dropdown-toggle"
                  >
                    {totalItems >= 1 ? (
                      <span className="navbar-tool-label">{totalItems}</span>
                    ) : null}
                    <i className="navbar-tool-icon bi bi-cart-plus" />
                  </Link>
                  <Link
                    className="navbar-tool-text"
                    to="/Shop-Cart"
                    onClick={scrollToTop}
                  >
                    <small>{t("my_Cart")}</small>
                    <span style={{ fontSize: "12px" }}>{doviz}</span>
                    {parseFloat(totalPrice * Para).toFixed(2)}
                  </Link>
                  {/* Cart dropdown*/}
                  <div className="dropdown-menu dropdown-menu-end">
                    <div
                      className="widget widget-cart px-3 pt-2 pb-3"
                      style={{ width: "20rem" }}
                    >
                      {cart.map((e) => {
                        const itemInCart = data.find(
                          (item) => item.id === e.id
                        );
                        return (
                          <div
                            key={itemInCart.id}
                            data-simplebar=""
                            data-simplebar-auto-hide="false"
                          >
                            <div className="widget-cart-item pb-2 border-bottom">
                              <Link
                                className="btn-close text-danger"
                                onClick={() =>
                                  dispatch({
                                    type: "remove",
                                    id: itemInCart.id,
                                    colorId: e.colorId,
                                  })
                                }
                                aria-label="Remove"
                              >
                                <span aria-hidden="true">×</span>
                              </Link>
                              <div className="d-flex align-items-center">
                                <Link className="flex-shrink-0" href="#">
                                  <img
                                    src={`${Url}/product_imgs/${itemInCart.images[0].product_photo}`}
                                    width={64}
                                    alt="Product"
                                  />
                                </Link>
                                <div className="ps-2">
                                  <h6 className="widget-product-title">
                                    <a href="#">
                                      {itemInCart[t("card.title")]}
                                    </a>
                                  </h6>
                                  <div className="widget-product-meta">
                                    <span style={{ fontSize: "12px" }}>
                                      {doviz}
                                    </span>
                                    <span className="text-accent me-2">
                                      {itemInCart.in_discount == 1
                                        ? parseFloat(
                                            itemInCart.discount_price * Para
                                          ).toFixed(2)
                                        : parseFloat(
                                            itemInCart.product_price * Para
                                          ).toFixed(2)}
                                    </span>
                                    <span className="text-muted">
                                      x {e.count}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
                        <div className="fs-sm me-2 py-2">
                          <span className="text-muted">{t("total")}:</span>
                          <span style={{ fontSize: "12px" }}>{doviz}</span>
                          <span className="text-accent fs-base ms-1">
                            {parseFloat(totalPrice * Para).toFixed(2)}
                          </span>
                        </div>
                        <div className="fs-sm me-2 py-2">
                          <span className="text-muted">{t("number")}:</span>
                          <span className="text-accent fs-base ms-1">
                            {totalItems}
                          </span>
                        </div>
                      </div>
                      <Link
                        className="btn btn-primary btn-sm d-block w-100"
                        to="/Shop-Cart"
                        onClick={scrollToTop}
                      >
                        <i className="bi bi-credit-card me-2 fs-base align-middle" />
                        {t("shop_cart")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar navbar-expand-lg navbar-light navbar-stuck-menu mt-n2 pt-0 pb-2">
            <div className="container">
              <div className="collapse navbar-collapse" id="navbarCollapse">
                {/* Search*/}
                <div className="input-group d-lg-none my-3">
                  <i className="bi bi-search position-absolute top-50 start-0 translate-middle-y text-muted fs-base ms-3" />
                  <input
                    className="form-control rounded-start"
                    type="text"
                    placeholder={t("search")}
                  />
                </div>
                {/* Primary menu*/}
                <ul dir={t("dir")} className="navbar-nav">
                  <li className="nav-item  ">
                    <Link onClick={scrollToTop} className="nav-link " to="/">
                      <div
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                      >
                        {t("navbar.home")}
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link
                      onClick={scrollToTop}
                      className="nav-link "
                      to="/About"
                    >
                      <div
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                      >
                        {t("navbar.about")}
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    {/* Dropdown */}
                    <Link
                      className="nav-link dropdown"
                      onClick={scrollToTop}
                      to="/Products"
                    >
                      <div
                        className=" dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {t("navbar.products")}
                      </div>
                      <div className="dropdown-menu dropdown-menu-end">
                        {Categories.map((e, key) => {
                          if (!uniqueCategories.has(e.categori_name_ar)) {
                            uniqueCategories.add(e.categori_name_ar);
                            return (
                              <Link
                                key={key}
                                to={`/Products/${e.id}`}
                                onClick={() => handleCategoryClick(e.id)}
                                className="dropdown-item"
                              >
                                <div
                                  data-bs-toggle="collapse"
                                  data-bs-target="#navbarCollapse"
                                >
                                  {e[t("card.categori")]}
                                </div>
                              </Link>
                            );
                          }
                          return null;
                        })}
                        <div className="dropdown-divider" />
                        <Link to="/Products" className="dropdown-item">
                          <div
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse"
                          >
                            {t("navbar.products")}
                          </div>
                        </Link>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link
                      onClick={scrollToTop}
                      className="nav-link "
                      to="/Contact"
                    >
                      <div
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                      >
                        {t("navbar.contact")}
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
