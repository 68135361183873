import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Url } from "../../../context/types/url";
import { BsCart2 } from "react-icons/bs";
import { PiPackageDuotone } from "react-icons/pi";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import { animateScroll as scroll } from "react-scroll";
import { useTranslation } from "react-i18next";
import { addItem } from "../../../context/actions/postaction";

const CheckoutDetails = () => {
  const { price } = useSelector((state) => state.price);
  const { doviz } = useSelector((state) => state.doviz);
  const Para = price[doviz];
  const navigate = useNavigate();
  const [Eroor, setEroor] = useState("d-none");
  const [Name, setName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [t] = useTranslation("global");
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1100,
      smooth: "easeInOutQuint",
    });
  };
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const note = useSelector((state) => state.note);
  const { data } = useSelector((state) => state.data);
  const totalPrice = cart.reduce((total, item) => {
    const product = data.find((product) => product.id === item.id);
    return (
      total +
      [
        product.in_discount == 1
          ? product.discount_price
          : product.product_price,
      ] *
        item.count
    );
  }, 0);

  const generateRandomCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "";
    const codeLength = 8;

    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }

    return code;
  };

  const handleSubmit = async () => {
    if (!Name || !LastName || !Email || !Phone) {
      // إحدى الحقول فارغة، لا يمكن إرسال الطلب
      setEroor("");
      scrollToTop();
      return;
    }

    const orderCode = generateRandomCode();
    const itemData = {
      items: cart.map((item) => ({
        name: Name,
        last_name: LastName,
        email: Email,
        phone: Phone,
        id: parseInt(item.id),
        piece: item.count,
        order_code: orderCode,
        color_id: item.colorId,
        note: note,
      })),
    };

    try {
      const response = await dispatch(addItem(itemData.items));
      if (response.status === 200) {
        setResponseStatus("success");
        setName("");
        setLastName("");
        setEmail("");
        setPhone("");
        navigate(`/Complete/${orderCode}`);
        scrollToTop();
      } else {
        setResponseStatus("error");
      }
    } catch (error) {
      setResponseStatus("error");
    }

    dispatch({ type: "clearCart" });
  };

  return (
    <>
      <div className="page-title-overlap bg-dark pt-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                <li className="breadcrumb-item">
                  <Link className="text-nowrap" to="/" onClick={scrollToTop}>
                    {t("navbar.home")}
                  </Link>
                </li>
                <li>
                  <SlArrowRight color="white" />
                </li>
                <li className="breadcrumb-item text-nowrap">
                  <Link to="/Products" onClick={scrollToTop}>
                    {t("navbar.products")}
                  </Link>
                </li>
                <li>
                  <SlArrowRight color="white" />
                </li>
                <li
                  className="breadcrumb-item text-nowrap active"
                  aria-current="page"
                >
                  {t("Shop_Cart.checkout")}
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 className="h3 text-light mb-0">{t("Shop_Cart.checkout")}</h1>
          </div>
        </div>
      </div>
      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          <section className="col-lg-8">
            {/* Steps*/}
            <div className="steps steps-light pt-2 pb-3 mb-5">
              <Link className="step-item active" to="/Shop-Cart">
                <div className="step-progress">
                  <span className="step-count">1</span>
                </div>
                <div className="step-label">
                  <BsCart2 size={20} className="m-1" />
                  {t("Shop_Cart.cart")}
                </div>
              </Link>
              <Link className="step-item active" to="/checkout-details">
                <div className="step-progress">
                  <span className="step-count">2</span>
                </div>
                <div className="step-label">
                  <PiPackageDuotone size={20} />
                  {t("Shop_Cart.details")}
                </div>
              </Link>
            </div>
            {/* Shipping address*/}
            <h2 className="h6 pt-1 pb-3 mb-3 border-bottom">
              {t("Shop_Cart.shipping_address")}
            </h2>

            <div
              className={`text-center p-3 ${Eroor}`}
              style={{
                backgroundColor: "rgba(121, 66, 163, 0.17)",
                borderRadius: "10px",
              }}
            >
              <span className=" text-danger  ">{t("Eroor")}</span>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="checkout-fn">
                    {t("Shop_Cart.first_name")}
                  </label>
                  <input
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    value={Name}
                    className="form-control"
                    type="text"
                    id="checkout-fn"
                    required
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="checkout-ln">
                    {t("Shop_Cart.last_name")}
                  </label>
                  <input
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    value={LastName}
                    className="form-control"
                    type="text"
                    id="checkout-ln"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="checkout-email">
                    {t("Shop_Cart.email_address")}
                  </label>
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={Email}
                    className="form-control"
                    type="email"
                    id="checkout-email"
                    required
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="checkout-phone">
                    {t("Shop_Cart.phone_number")}
                  </label>
                  <input
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    value={Phone}
                    className="form-control"
                    type="text"
                    id="checkout-phone"
                    required
                  />
                </div>
              </div>
            </div>
            {/* Navigation (desktop)*/}
            <div className="d-none d-lg-flex pt-4 mt-3">
              <div className="w-50 pe-3">
                <Link
                  className="btn btn-secondary d-block w-100"
                  to="/Shop-Cart"
                >
                  <SlArrowLeft className="ci-arrow-left mt-sm-0 me-1" />
                  <span className="d-none d-sm-inline">
                    {t("Shop_Cart.back_to_cart")}
                  </span>
                  <span className="d-inline d-sm-none">
                    {t("Shop_Cart.back")}
                  </span>
                </Link>
              </div>
              <div className="w-50 ps-2">
                <Link
                  className="btn btn-primary d-block w-100"
                  onClick={handleSubmit}
                >
                  <span className="d-none d-sm-inline">
                    {t("Shop_Cart.complete_order")}
                  </span>
                  <span className="d-inline d-sm-none">
                    {t("Shop_Cart.complete")}
                  </span>
                  <SlArrowRight className="ci-arrow-right mt-sm-0 ms-1" />
                </Link>
              </div>
            </div>
          </section>
          {/* Sidebar*/}
          <aside className="col-lg-4 pt-4 pt-lg-0 ps-xl-5">
            <div className="bg-white rounded-3 shadow-lg p-4 ms-lg-auto">
              <div className="py-2 px-xl-2">
                <div className="widget mb-3">
                  <h2 className="widget-title text-center">
                    {t("Shop_Cart.order_summary")}
                  </h2>
                  {cart.map((e) => {
                    const itemInCart = data.find((item) => item.id === e.id);
                    return (
                      <div
                        key={itemInCart.id}
                        className="d-flex align-items-center pb-2 border-bottom"
                      >
                        <a className="d-block flex-shrink-0" href="#">
                          <img
                            src={`${Url}/product_imgs/${itemInCart.images[0].product_photo}`}
                            width={64}
                            alt="Product"
                          />
                        </a>
                        <div className="ps-2">
                          <h6 className="widget-product-title">
                            <a href="#">{itemInCart[t("card.title")]}</a>
                          </h6>
                          <div className="widget-product-meta">
                            <span style={{ fontSize: "12px" }}>{doviz}</span>
                            <span className="text-accent me-2">
                              {parseFloat(
                                itemInCart.product_price * Para
                              ).toFixed(2)}
                            </span>
                            <span className="text-muted">x {e.count}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <ul className="list-unstyled fs-sm pb-2 border-bottom">
                  <li className="d-flex justify-content-between align-items-center">
                    <span className="me-2">{t("Shop_Cart.subtotal")}</span>
                    <span className="text-end">
                      <span style={{ fontSize: "12px" }}>{doviz}</span>
                      {parseFloat(totalPrice * Para).toFixed(2)}
                    </span>
                  </li>
                  {/* <li className="d-flex justify-content-between align-items-center">
                    <span className="me-2">Shipping:</span>
                    <span className="text-end">—</span>
                  </li>
                  <li className="d-flex justify-content-between align-items-center">
                    <span className="me-2">Taxes:</span>
                    <span className="text-end">
                      $9.<small>50</small>
                    </span>
                  </li>
                  <li className="d-flex justify-content-between align-items-center">
                    <span className="me-2">Discount:</span>
                    <span className="text-end">—</span>
                  </li> */}
                </ul>
                <h3 className="fw-normal text-center my-4">
                  <span style={{ fontSize: "20px" }}>{doviz}</span>
                  {parseFloat(totalPrice * Para).toFixed(2)}
                </h3>
              </div>
            </div>
          </aside>
        </div>
        {/* Navigation (mobile)*/}
        <div className="row d-lg-none">
          <div className="col-lg-8">
            <div className="d-flex pt-4 mt-3">
              <div className="w-50 pe-3">
                <Link
                  className="btn btn-secondary d-block w-100"
                  to="/Shop-Cart"
                >
                  <SlArrowLeft className="ci-arrow-left mt-sm-0 me-1" />
                  <span className="d-none d-sm-inline">
                    {t("Shop_Cart.back_to_cart")}
                  </span>
                  <span className="d-inline d-sm-none">
                    {t("Shop_Cart.back")}
                  </span>
                </Link>
              </div>
              <div className="w-50 ps-2">
                <Link
                  className="btn btn-primary d-block w-100"
                  onClick={handleSubmit}
                >
                  <span className="d-none d-sm-inline">
                    {t("Shop_Cart.complete_order")}
                  </span>
                  <span className="d-inline d-sm-none">
                    {t("Shop_Cart.complete")}
                  </span>
                  <SlArrowRight className="ci-arrow-right mt-sm-0 ms-1" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutDetails;
