import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Url } from "../../../context/types/url";
import { animateScroll as scroll } from "react-scroll";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BsFilterLeft } from "react-icons/bs";
import { useTranslation } from "react-i18next";
const Card = ({ activeCategory, activeBrand }) => {
  const { price } = useSelector((state) => state.price);
  const { doviz } = useSelector((state) => state.doviz);
  const Para = price[doviz];
  const [t] = useTranslation("global");
  const { data } = useSelector((state) => state.data);
  const { color } = useSelector((state) => state.color);
  const [colorId, setColorId] = useState();
  const filtered =
    parseInt(activeCategory) > 0
      ? data.filter((item) => item.categori_id == activeCategory)
      : data;
  const filteredData =
    activeBrand.length > 0
      ? filtered.filter((product) =>
          activeBrand.includes(`${product.marka_id}`)
        )
      : filtered;
  const [id, setId] = useState();
  const dispatch = useDispatch();
  const [imageId, setImageId] = useState();
  const productsInfo = filteredData.find((i) => i.id === parseInt(id));
  const productsImages = productsInfo
    ? productsInfo.images.find((i) => i.id == imageId)
    : null;
  const filtirColor = productsInfo
    ? color.filter((e) => e.product_code == productsInfo.product_code)
    : null;
  const myRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (myRef.current && !myRef.current.contains(e.target)) {
        setImageId("");
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1100,
      smooth: "easeInOutQuint",
    });
  };

  const InfoData = data.filter((i) => i.id == id);
  const comments = InfoData.reduce(
    (acc, comment) => [...acc, ...comment.comments],
    []
  );
  const totalRate = comments.reduce(
    (acc, comment) => parseInt(acc) + parseInt(comment.c_rate),
    0
  );
  const averageRate = totalRate / comments.length;
  const decimalPart = averageRate.toFixed(1);

  const StarRating = (
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((index) => (
        <i
          key={index}
          className={`star-rating-icon active bi bi-star${
            index <= Math.floor(decimalPart) ? "-fill" : ""
          }`}
        />
      ))}
    </div>
  );

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDisplay = filteredData.slice(startIndex, endIndex);
  return (
    <>
      <section className="col-lg-8">
        {/* Toolbar*/}
        <div className="d-flex justify-content-center justify-content-sm-between align-items-center pt-2 pb-4 pb-sm-5">
          <div className="d-flex flex-wrap">
            <div className="d-flex align-items-center flex-nowrap me-3 me-sm-4 pb-3">
              <span className="fs-sm text-light opacity-75 text-nowrap ms-2 d-none d-md-block">
                {filteredData.length} {t("navbar.products")}
              </span>
            </div>
          </div>
          <div className="d-flex pb-3">
            <a
              className="d-table-cell handheld-toolbar-item d-block d-lg-none "
              href="#"
              data-bs-toggle="offcanvas"
              data-bs-target="#shop-sidebar"
            >
              <span className="handheld-toolbar-icon ">
                <BsFilterLeft size={24} color="white" />
              </span>
            </a>
          </div>
        </div>
        {/* Products grid*/}
        <div dir={t("dir")} className="row mx-n2">
          {/* Product*/}
          {itemsToDisplay.map((e, key) => {
            const InfoData = data.filter((i) => i.id == e.id);
            const comments = InfoData.reduce(
              (acc, comment) => [...acc, ...comment.comments],
              []
            );
            console.log(comments);
            const totalRate = comments.reduce(
              (acc, comment) => parseInt(acc) + parseInt(comment.c_rate),
              0
            );
            const averageRate = totalRate / comments.length;
            const decimalPart = averageRate.toFixed(1);

            const StarRating = (
              <div className="star-rating">
                {[1, 2, 3, 4, 5].map((index) => (
                  <i
                    key={index}
                    className={`star-rating-icon active bi bi-star${
                      index <= Math.floor(decimalPart) ? "-fill" : ""
                    }`}
                  />
                ))}
              </div>
            );
            return (
              <div
                key={key}
                className="col-lg-3 col-md-4 col-sm-6  col-6 px-2 mb-4"
              >
                <div className="card product-card h-100 border">
                  <Link
                    onClick={scrollToTop}
                    className="card-img-top d-block overflow-hidden"
                    to={`/InfoCard/${e.id}`}
                  >
                    <img
                      src={`${Url}/product_imgs/${e.images[0].product_photo}`}
                      alt="Product"
                      className="card-img"
                    />
                  </Link>
                  <div className="card-body py-2">
                    <a className="product-meta d-block fs-xs pb-1" href="#">
                      {e.categori[t("card.categori")]}
                    </a>
                    <h3 className="product-title fs-sm">
                      <Link to={`/InfoCard/${e.id}`}>{e[t("card.title")]}</Link>
                    </h3>
                    <div className="d-block">
                      <div className="product-price">
                        <span style={{ fontSize: "12px" }}>{doviz}</span>
                        <span className="text-accent m-1">
                          {e.in_discount == 1
                            ? parseFloat(e.discount_price * Para).toFixed(2)
                            : parseFloat(e.product_price * Para).toFixed(
                                2
                              )}{" "}
                        </span>

                        {e.in_discount == 1 ? (
                          <del className="fs-sm text-muted">
                            {parseFloat(e.product_price * Para).toFixed(2)}
                          </del>
                        ) : null}
                      </div>
                      {StarRating}
                    </div>
                  </div>

                  <div className="card-body card-body-hidden">
                    <Link
                      className="btn btn-primary btn-sm d-block w-100 mb-2"
                      onClick={() => {
                        const filterColor = color.filter(
                          (c) => c.product_code == e.product_code
                        );
                        const selectedColorId =
                          colorId ||
                          (filterColor.length > 0 ? filterColor[0].id : null);
                        dispatch({
                          type: "add",
                          id: e.id,
                          colorId: selectedColorId,
                        });
                      }}
                    >
                      <i className="bi bi-cart-plus fs-sm me-1" />
                      {t("card.add_to_cart")}
                    </Link>
                    <div className="text-center">
                      <a
                        className="nav-link-style text-success fs-ms"
                        target="_blank"
                        href={`https://api.whatsapp.com/send?phone=+96890118236&text=مرحبًا%0Aأنا أود شراء المنتج ${e.product_code}%0Aيرجى تقديم المزيد من المعلومات حول المنتج وكيفية الشراء.`}
                        data-action="share/whatsapp/share"
                      >
                        <i className="bi bi-whatsapp align-middle mx-1" />
                        {t("card.quick_view")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <hr className="my-3" />
        {/* Pagination */}
        <nav
          dir="ltr"
          className="d-flex justify-content-between pt-2"
          aria-label="Page navigation"
        >
          <ul className="pagination">
            <li className="page-item">
              <Link
                className="page-link"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
                {t("products.prev")}
              </Link>
            </li>
          </ul>
          <ul className="pagination">
            {Array.from({
              length: Math.ceil(filteredData.length / itemsPerPage),
            }).map((_, index) => (
              <li
                key={index}
                className={`page-item${
                  index + 1 === currentPage ? " active" : ""
                }`}
              >
                <Link
                  className="page-link"
                  onClick={() => setCurrentPage(index + 1)}
                >
                  {index + 1}
                  {index + 1 === currentPage && (
                    <span className="visually-hidden">(current)</span>
                  )}
                </Link>
              </li>
            ))}
          </ul>
          <ul className="pagination">
            <li className="page-item">
              <Link
                className="page-link"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={endIndex >= filteredData.length}
              >
                {t("products.next")}
                <IoIosArrowForward />
              </Link>
            </li>
          </ul>
        </nav>
      </section>
      {/* Quick View Modal*/}
      <div
        dir="ltr"
        className="modal-quick-view modal fade"
        id="quick-view"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title product-title">
                <Link
                  onClick={scrollToTop}
                  to={productsInfo ? `/InfoCard/${productsInfo.id}` : null}
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="Go to product page"
                >
                  {productsInfo ? productsInfo[t("card.title")] : null}
                  <i className="bi bi-arrow-right fs-lg ms-2" />
                </Link>
              </h4>
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setImageId("");
                }}
              />
            </div>
            <div ref={myRef} className="modal-body">
              <div className="row">
                {/* Product gallery*/}
                <div className="col-lg-7 pe-lg-0">
                  <div className="product-gallery">
                    <div className="product-gallery-preview order-sm-2">
                      <div
                        className="product-gallery-preview-item active"
                        id="first"
                      >
                        <img
                          className="image-zoom"
                          src={
                            productsInfo
                              ? `${Url}/product_imgs/${
                                  imageId > 0
                                    ? productsImages.product_photo
                                    : productsInfo.images[0].product_photo
                                }`
                              : null
                          }
                          alt="Product image"
                        />
                      </div>
                    </div>
                    <div className="product-gallery-thumblist order-sm-1">
                      {productsInfo
                        ? productsInfo.images.map((e, i) => (
                            <a
                              key={i}
                              className="product-gallery-thumblist-item active"
                              onClick={() => {
                                setImageId(e.id);
                              }}
                            >
                              <img
                                src={`${Url}/product_imgs/${e.product_photo}`}
                                alt="Product thumb"
                              />
                            </a>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
                {/* Product details*/}
                <div className="col-lg-5 pt-4 pt-lg-0 image-zoom-pane">
                  <div className="product-details ms-auto pb-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <a href="#">
                        {StarRating}
                        <span className="d-inline-block fs-sm text-body align-middle mt-1 ms-1">
                          {comments.length} {t("card.reviews")}
                        </span>
                      </a>
                    </div>
                    <div className="mb-3">
                      {doviz}
                      <span className="h3 fw-normal text-accent me-1">
                        {productsInfo
                          ? productsInfo.in_discount == 1
                            ? parseFloat(
                                productsInfo.discount_price * Para
                              ).toFixed(2)
                            : parseFloat(
                                productsInfo.product_price * Para
                              ).toFixed(2)
                          : null}
                      </span>
                      {productsInfo ? (
                        productsInfo.in_discount == 1 ? (
                          <>
                            <del className="text-muted fs-lg me-3">
                              {parseFloat(
                                productsInfo.product_price * Para
                              ).toFixed(2)}
                            </del>
                            <span className="badge bg-danger badge-shadow align-middle mt-n2">
                              {t("card.discount")}
                            </span>
                          </>
                        ) : null
                      ) : null}
                    </div>
                    <div className="fs-sm mb-4">
                      <div className="position-relative me-n4 mb-3">
                        {productsInfo
                          ? filtirColor.map((e, key) => (
                              <div
                                key={key}
                                className="form-check form-option form-check-inline mb-2"
                              >
                                <input
                                  onClick={() => {
                                    setColorId(e.id);
                                  }}
                                  className="form-check-input"
                                  type="radio"
                                  name="color"
                                  id={e.id}
                                  data-bs-label="colorOption"
                                  defaultValue="Dark blue/Orange"
                                  defaultChecked=""
                                />
                                <label
                                  className="form-option-label rounded-circle"
                                  htmlFor={e.id}
                                >
                                  <span
                                    className="form-option-color rounded-circle"
                                    style={{
                                      backgroundColor: `${e.colors_code}`,
                                    }}
                                  />
                                </label>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>

                    <form className="mb-grid-gutter">
                      <div className="mb-3 d-flex align-items-center">
                        <Link
                          className="btn btn-primary btn-shadow d-block w-100"
                          onClick={() => {
                            const selectedColorId =
                              colorId ||
                              (filtirColor.length > 0
                                ? filtirColor[0].id
                                : null);
                            dispatch({
                              type: "add",
                              id: productsInfo.id,
                              colorId: selectedColorId,
                            });
                          }}
                          aria-label="Close"
                          data-bs-dismiss="modal"
                        >
                          <i className="bi bi-cart-plus fs-lg me-2" />
                          {t("card.add_to_cart")}
                        </Link>
                      </div>
                    </form>
                    <h5 className="h6 mb-3 pb-2 border-bottom">
                      <i className="bi bi-info-circle text-muted fs-lg align-middle mt-n1 me-2" />
                      {t("card.details")}
                    </h5>
                    <h6
                      className="fs-sm mb-2"
                      dangerouslySetInnerHTML={{
                        __html: productsInfo
                          ? productsInfo[t("card.description")]
                          : null,
                      }}
                    />
                    <h6 dir={t("dir")} className="fs-sm mb-2">
                      {t("card.product_code")}{" "}
                      {productsInfo ? productsInfo.product_code : null}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
