import React, { useEffect } from "react";
import "tiny-slider/dist/tiny-slider.css";
import { tns } from "tiny-slider";
import { useSelector } from "react-redux";
import Image from "../../images/jo93715bfhet.png";

const Follow = () => {
  const { contact } = useSelector((state) => state.contact);
  useEffect(() => {
    const slider = tns({
      container: ".tns-carousel-inner",
      items: 1,
      gutter: 20,
      mouseDrag: true,
      controlsContainer: false,
      autoplayTimeout: 5000,
      responsive: {
        0: { items: 1 },
        576: { items: 2 },
        1200: { items: 3 },
        1560: { items: 4 },
      },
    });
  }, []);
  console.log(contact);
  return (
    <section className="py-5 bg-light rounded-3 my-4 my-md-5 px-3 px-sm-4">
      <h2 className="h3 py-4 text-center">Customer reviews</h2>
      <div className="tns-carousel mb-3">
        <div className="tns-carousel-inner">
          {contact.map((e, key) => {
            return (
              <blockquote key={key} className="mb-2">
                <div className="card border-0">
                  <div className="card-body px-3 fs-md text-muted">
                    <div className="mb-2">
                      <div className="star-rating">
                        {[1, 2, 3, 4, 5].map((index) => (
                          <i
                            key={index}
                            className={`star-rating-icon active bi bi-star${
                              index <= Math.floor(e.rate) ? "-fill" : ""
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                    {e.message}
                  </div>
                </div>
                <footer className="d-flex justify-content-center align-items-center pt-4">
                  <img
                    className="rounded-circle"
                    src={Image}
                    width={50}
                    alt="Richard Davis"
                  />
                  <div className="ps-3">
                    <h6 className="fs-sm mb-n1">{e.title}</h6>
                    <span className="fs-ms text-muted opacity-75">
                      {new Date(e.created_at).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </span>
                  </div>
                </footer>
              </blockquote>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Follow;
